;(function ( $, window, document, undefined ) {

	"use strict";

	var pluginName = "vaAjaxRefresh",
	defaults = {};

	function Plugin ( element, options ) {
		this.element = element;
		var defaults = {
			url: $(this.element).data('url')
		};
		this.data = $(this.element).data();
		delete this.data.url
		this.settings = $.extend( {}, defaults, options );
		this.init();
	}

	$.extend(Plugin.prototype, {
		init: function () {
			var that = this;
			$.ajax({
				type: "POST",
				url: that.settings.url + "?_ts=" + Date.now(),
				data: that.data,
				dataType: "html",
				success: function (result) {
					$(that.element).html(result);
                    //$(document).scrollTop( $(that.element).offset().top );  
					return false;
				}
			});
		}
	});

	$.fn[ pluginName ] = function ( options ) {
		return this.each(function() {
			$.data(this, 'plugin_' + pluginName, null);
			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, options ) );
			}
		});
	};

})( jQuery, window, document );
