;(function ( $, window, document, undefined ) {

	"use strict";

	var pluginName = "vaAjaxForm",
	defaults = {};

	function Plugin ( element, callback ) {
		defaults = {
			url: $(element).attr('action'),
			method: $(element).attr('method'),
			callback: function() {
				return false;
			}
		};
		if (callback) defaults.callback = callback;
		this.element = element;
		this.settings = defaults;
		this.init();
	}

	$.extend(Plugin.prototype, {
		init: function () {
			this.form = $(this.element);
			this.data = [];
			var that = this;
			this.form.unbind('submit').on('submit', function(e) {
				e.preventDefault();
				var updateForm = that.form;
				updateForm.find('label.error').remove();
				if (typeof HTMLFormElement.prototype.checkValidity == 'function' && !$(updateForm)[0].checkValidity()) {
					updateForm.find( ":invalid" ).each( function( index, node ) {
						$(node).after( "<label class='error'>" + node.validationMessage + "</label>" );
					});
					updateForm.find( ":invalid" ).first().focus();
					return false
				}
                if(updateForm.has(":input"))
					that.data = updateForm.serializeArray();
				else
					that.data = {};

				that.makeCall();
			});
		},
		makeCall: function () {
			var that = this,
			updateForm = that.form,
			form = new FormData($(updateForm)[0]),
			//xhr = new window.XMLHttpRequest(),
			xhr,
			p = 0,
			data;

			if (window.ActiveXObject)
			{
				 xhr = new window.ActiveXObject('Microsoft.XMLHTTP');
			}
			else
			{
				 xhr = new window.XMLHttpRequest();
            }			

			xhr.upload.addEventListener("progress", function (e) {
				if (e && e.lengthComputable) {
					p = (e.loaded / e.total);
					var percent = Math.round(p * 1000) / 10;
				}
			}, false);
 
			xhr.addEventListener("readystatechange", function(e) {
				if (this.readyState === 4) {
					data = $.parseJSON(xhr.responseText);
					that.settings.callback(data);
				}
			}, false);

			xhr.open(that.form.attr('method') || "post", that.form.attr('action') + "?_ts=" + new Date().getTime(), true);
                        xhr.setRequestHeader("If-Modified-Since", new Date().toUTCString());
               
			xhr.send(form);
		}
	});

	$.fn[ pluginName ] = function ( callback ) {
		return this.each(function() {
			$.data(this, 'plugin_' + pluginName, null);			
			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, callback ) );
			}
		});
	};

})( jQuery, window, document );

