    function sleep(milliseconds) 
	{
      var start = new Date().getTime();
      for (var i = 0; i < 1e7; i++) 
	  {
         if ((new Date().getTime() - start) > milliseconds){ break; }
      }
    }
	
	function confirm_delete(p_url) 
	{
		var answer = confirm ("Are you sure you want to remove this entry?")
		if (answer)
		  window.location.href = p_url;

	}

	jQuery.fn.sortDivs = function sortDivs() {
		$("> div.dashboard-widget", this[0]).sort(dec_sort).appendTo(this[0]);
		function dec_sort(a, b){ return ($(b).data("position")) < ($(a).data("position")) ? 1 : -1; }
	}
		
	var conditionalSubmit = function(event, selector) {
		
	   if (typeof HTMLFormElement.prototype.checkValidity == 'function' && !$(selector)[0].checkValidity()) {
		  event.stopImmediatePropagation();
		  return false;
	   } 
	   else {

			  if(!$(selector).valid())
			  {
				  event.stopImmediatePropagation();
				  return false;
			  }

			  $(selector + ' :submit').trigger('click');
			  return true;

			  
	   }
	}

	var submitMultipleForms = function(event, selector) { 
		
		var result = true;
		
		$(selector).each(function(){
			if(!$(this).valid())
		    {
 		       result = false;
			   //console.log("#" + $(this).attr("id") + " invalid");
			   event.stopImmediatePropagation();
		    }
			else
			{
			   //$(this).find("input[type='submit']").click();
			   //console.log("#" + $(this).attr("id") + " :submit");
			   $("#" + $(this).attr("id") + " :submit").trigger('click');
			   //$(selector + ' :submit').trigger('click');
			   $(this).removeClass("dirty");
   		}
  
            sleep(250);
		   
		});

		
		return result;
		
	}

   if (!String.prototype.startsWith) {
	  String.prototype.startsWith = function(searchString, position) {
		position = position || 0;
		return this.indexOf(searchString, position) === position;
	  };
   }
   
   if (typeof console == "undefined") {
      this.console = {log: function() {}};
   }

  function bindControls(){

      $('.editor').wysihtml5({
		  "stylesheets": ["/va36/css/wysiwyg-color.css"] // CSS stylesheets to load
		});
      $('.datefield').datepicker({format: 'dd/mm/yyyy'});
      $('.numeric').numeric();
      $('.expander').expander();
      $('.paginatedtable').tablePagination();
      $('.sortable-table tbody').sortable().disableSelection();
      $('.sortable').sortable().disableSelection();
      $('.expandingtable').tableExpander();
      $('.sortedtable').tablesorter({dateFormat : "dd/mm/yyyy"});
      $('.master').master();
      $('.treegrid').treegrid({initialState: "collapsed"});
	  
      $('input:checkbox[data-target-disable]').each(function() {
	  if(!$(this).is(':checked')){
             $('#' + $(this).data('target-disable')).attr('disabled', 'disabled');
          }
      });

      $('input:checkbox[data-target-disable]').change(function() {
          if(!$(this).is(':checked')){
             $('#' + $(this).data('target-disable')).attr('disabled', 'disabled');
          }
          else {
              $('#' + $(this).data('target-disable')).removeAttr('disabled');
          }
      });
	  
	  //$('input[data-inputmask]').each(function(){
		  //$(this).inputmask();
	  //});

      $('form').each(function() { 
        $(this).validate({     
          focusInvalid: true,
          invalidHandler: function(form, validator) {
            if (!validator.numberOfInvalids())
               return;

            $('html, body').animate({scrollTop: $(validator.errorList[0].element).offset().top   }, 500);

          }
        });
      });
	  
      $('.tooltip-demo').tooltip({html: true, selector: "a[rel=tooltip]"});
	  
      $(".table-filter").on("keyup", function() {

        var value = $(this).val().toLowerCase();
        var target = $(this).data("search-target");
         
		$("#" + target + " tbody tr").filter(function() {
           $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
		   if (value.length >= 1)
		      $("#" + target).parent().find("div.pagination").hide();
		   else
		      $("#" + target).parent().find("div.pagination").show();
        });
      });	  

  }

$( document ).ajaxComplete(function() {
        bindControls(); 
});

$(document).ready( function () {
       
        bindControls(); 
        //Handling sorting for paged tables
        $('.paginatedtable').bind("sortEnd",function() { 
           $('.paginatedtable').tablePagination();
         }); 

        //Handling sorting for expanding tables
        $('.expandingtable').bind("sortEnd",function() { 
           $('.expandingtable').tableExpander();
         }); 
    
        // check / uncheck all function
        $("input:checkbox.checkall").click(function(e)
        {
           $(this).parents().find('input:checkbox.'+$(e.target).data('target-class')).attr('checked', this.checked);
        });
    
/*        $(".master").on('change', function(e) {

         if($(this).val()=='')
            return false;

         var masterMode = $(this).data("mode")?$(this).data("mode"):"refresh";

		 if(masterMode=="refresh")
		 {
			 var slaveId = $(this).data("slave");
			 var lovName = $("#" + slaveId).data("lov-type");
			 var data = "lov=" + lovName + "&" + $(this).closest('form').serialize();
			 
			 $.ajax({
			  url : '!w_va_md_form.lovparam',
			  type : 'POST',
			  data : data,
			  success : function(data) {
			   data = JSON.parse(data);
			   $("#" + slaveId).find('option')
			   .remove()
			   .end()
			   $.each(data, function(key, value) {   
				 $("#" + slaveId)
				  .attr("value",key)
				  .append($("<option></option>")
				  .text(value)); 
			   });

			   if($("#" + slaveId).data("value") != "")
				 $("#" + slaveId).val($("#" + slaveId).data("value"));
			   
			  }
			 });
		}
		else if (masterMode=="disable")
		{
			 $.each($(this).data(), function(i, v) {
				 if (i.match("enable-condition-"))
				 {
					 if($("#" + i.replace("enable-condition-", "")).val()==v)
						$("#" + i.replace("enable-condition-", "")).prop('disabled', false);
					else
						$("#" + i.replace("enable-condition-", "")).prop('disabled', true);
				 }
				 alert('"' + i + '":"' + v + '",');
			 });
			 
		}
        }).trigger('change');*/

        $(document).on("focus", ".lov", function() {
         var lovParam = "";
         var lovType = $(this).data("lov-type");
         var lovField = $(this).data("lov-field");

         $.each($(this).data(), function(n, v){
            if(n.indexOf("lovParam")>=0)
               lovParam += n.replace("lovParam", "").toUpperCase() + "=" + v + "&";
         
         });

         $(this).typeahead({
           source : function(query, process) {
           $('[name=' + lovField + ']').val('');
           $.ajax({
            url : '!w_va_md_form.lov',
            type : 'POST',
            data : lovParam + 'LOV=' + lovType + '&QUERY=' + escape(query),
            dataType : 'JSON',
            async : true,
            success : function(data) {
             events = [];
             map = {};
             $.each(data, function(i, event) {
              map[event.value] = event;
              events.push(event.value);
             });
             process(events);
            }
           });
          },
          updater : function(item) {
           id = map[item].id;
           $('[name=' + lovField + ']').val(id);
           return item;
          },
          matcher : function(item) {
           var regex = new RegExp('"', 'g');
           if (item.toLowerCase().indexOf(this.query.replace(regex, '' ).trim().toLowerCase()) != -1) 
           {
            return true;
           }          
          },
          sorter : function(items) {
           return items.sort();
          },
          highlighter : function(item) {
           var regex = new RegExp('(' + this.query + ')', 'gi');
           return item.replace(regex, "<strong>$1</strong>");
          },
          items : 15,
          minLength : 3
         });
         
         $(this).on('blur', function() {

            if($(this).val().length<3)
               $('[name=' + lovField + ']').val('');
         
            if(!$('[name=' + lovField + ']').val())
               $(this).val('');
         });

        });        

        $(document).on("click", ".cvinclude", function(event) {
           event.preventDefault();
           var id = $(this).data("checkbox");
           console.log(id);
           var block = $(this).data("block");
           var excludeLabel = $(this).data("excludelabel");
           var includeLabel = $(this).data("includelabel");
           
           /* Clicking on Exclude */
           if($(this).html() == excludeLabel)
           {
               /* console.log("exclude:"); */
               console.log($("#" + id));
               $("#" + id).removeAttr('checked');
               $("#" + block).addClass("muted");
               $(this).html(includeLabel);
           }
           /* Clicking on Include */
           else
           {
               /* console.log("include:"); */
               $("#" + id).attr('checked','checked');
               $("#" + block).removeClass("muted");
               $(this).html(excludeLabel);
           }
        });
        
        $(".cvincludeall").on("click", function(event)
        {
           var targetdiv = $(this).attr("data-targetid");
           var excludeLabel = $(this).attr("data-excludelabel");
           var includeLabel = $(this).attr("data-includelabel");
           var title = $(this).html();
           var mode = "include";

           /* Set label and mode */
           if (title == excludeLabel)
           {
              mode = "exclude";
              $(this).html(includeLabel);
           }
           else
           {
              mode = "include";
              $(this).html(excludeLabel);
           }
           
           $("#" + targetdiv + " .cvinclude").each(function()
           {
              var _excludeLabel = $(this).attr("data-excludelabel");
              var _includeLabel = $(this).attr("data-includelabel");
              var _label = $(this).html();
              
              if (mode=="include" && _label==_includeLabel)
                 $(this).click();

              if (mode=="exclude" && _label==_excludeLabel)
                 $(this).click();
               
           });
        });
        
        
        $(".ajaxform").on("submit", function(event)
        {
           event.preventDefault();
           if(!$(this).valid()) return;
           var data = $(this).serialize();
		   data += "&_ts=" + new Date().getTime();
           var url = $(this).attr("action");
           $.post(url, 
                  data, 
                  function(data)
                  {
                     console.log(data);
                  });
        });
        
        $(".ajaxrefresh").on("click", function(event)
        {
           event.preventDefault();
           var url = $(this).attr("data-ajaxurl");
           var formdata = $(this).attr("data-ajaxdata");
		   formdata += "&_ts=" + $(this).attr("action");
           var target = $(this).attr("data-ajaxtarget");
           // console.log(url);
           // console.log(formdata);
           $('#' + target).load(url, 
                                formdata,
                                function(data)
                                {
                                   $('.sortable-table tbody').sortable().disableSelection();
                                   $('.sortable').sortable().disableSelection();
                                   // console.log("success");
                                });

        });

        $(document).on("click", "a[data-confirm]", function(ev) {

		    var href = $(this).attr('href');
			var targetRefresh = $(this).data('target-refresh');
			
			if(!targetRefresh)
			{
				if (!$('#dataConfirmModal').length) {
					$('body').append('<div id="dataConfirmModal" class="modal" role="dialog" aria-labelledby="dataConfirmLabel" aria-hidden="true"><div class="modal-header"><a class="close" data-dismiss="modal">x</a><h3 id="dataConfirmLabel">Please Confirm</h3></div><div class="modal-body"></div><div class="modal-footer"><button class="btn" data-dismiss="modal" aria-hidden="true">Cancel</button><a class="btn btn-primary" id="dataConfirmOK">OK</a></div></div>');
				}
				$('#dataConfirmModal').find('.modal-body').text($(this).attr('data-confirm'));
				$('#dataConfirmOK').attr('href', href);
				$('#dataConfirmModal').modal({backdrop: 'static', keyboard: false, show:true});				
			}
			else
			{

				if (!$('#ajaxDataConfirmModal').length) {
					$('body').append('<div id="ajaxDataConfirmModal" class="modal" role="dialog" aria-labelledby="dataConfirmLabel" aria-hidden="true"><div class="modal-header"><a class="close" data-dismiss="modal">x</a><h3 id="dataConfirmLabel">Please Confirm</h3></div><div class="modal-body"></div><div class="modal-footer"><form id="ajaxDataConfirmForm" class="va-ajax-form" data-target-refresh="" method="get"> <button class="btn" data-dismiss="modal" aria-hidden="true">Cancel</button><input type="submit" class="btn btn-primary" id="dataConfirmForm" value="Ok"></form></div></div>');
				}
				$('#ajaxDataConfirmModal').find('.modal-body').text($(this).attr('data-confirm'));
				$('#ajaxDataConfirmForm').attr('action', href + "&_ts=" + new Date().getTime());
				$('#ajaxDataConfirmForm').attr('data-target-refresh', targetRefresh);
				$('#ajaxDataConfirmModal').modal({show:true});
                                $('#ajaxDataConfirmForm').vaAjaxForm(function(response) {
                                   $('#' + targetRefresh).vaAjaxRefresh();
                                   $('#ajaxDataConfirmModal').modal("hide");
                                });

			}			

            return false;
        });        
              
                
        $(".submitAll").on("click", function(event)
        {
           event.preventDefault();
           document.body.style.cursor = 'wait';
           var submitclass = $(this).data("submit-target");
           var href = $(this).attr('href');
           var temp = 0;
           
           $("form." + submitclass).each(function() {

               temp++;
               var data = $(this).serialize();
               var url = $(this).attr("action");
               $.post(url, data, function(data)
               {
                  temp--;
                  if(temp==0)
                  {
                     console.log('finished');
                     document.body.style.cursor = 'default';

                     if(href)
                        self.location=href;
                  }
                  
               });               
            });
           
        });
		
        url = document.location.href.split('#');
        if(url[1] != undefined) {
            $('.nav-tabs [href=#'+url[1]+']').tab('show');
        }

		/* Toggle blocks / left filter block functionality */
		/* Includes readings from session storage */
		$(".toggleBlock").change(function(ev) {
                      $("#" + $(this).data('target-by-id')).toggle($(this).is(":checked"));
	    	      if (window.localStorage) 
				saveToStorage();
		});

		var saveToStorage = function(){
			var status = [];
			$(".toggleBlock").each(function(index, checkbox) {
			status.push({
				id: $(checkbox).data('target-by-id'),
				checked: $(checkbox).is(':checked')
				});
			});
			localStorage.setItem(window.location.pathname, JSON.stringify(status));
		};

		var readFromStorage = function() {
			var status = $.parseJSON(localStorage.getItem(window.location.pathname));
			$.each(status, function(index, checkbox) {
				$('.toggleBlock[data-target-by-id="' + checkbox.id + '"]').attr('checked', checkbox.checked).trigger('change');
			});
		};
		
		if (window.localStorage && localStorage.getItem(window.location.pathname)) 
			readFromStorage();

		/* Functimns fmr AJAX Calls and AJAX refresh */
		/* Mainly used in the grants section         */
		$(document).delegate('.va-ajax-form :submit', 'click', function(e) {
		       var form = $(this).parents('form:first');
                       if(!$(this).parents('form:first').valid()) return; 
					form.vaAjaxForm(function(response) {
							if(response.success && form.attr('data-target-refresh')){

   							   if(form.attr('data-target-refresh')){
								  var refreshRegions = form.attr('data-target-refresh').split(",");
								     for (var i = 0; i < refreshRegions.length; i++)
										 $('#' + refreshRegions[i]).vaAjaxRefresh();

							      if(!form.attr('data-form-no-reset'))
								     $(form).trigger("reset");

							      if(form.attr('data-form-auto-close'))
								     $(form).remove();

							   }

							   $(form).prepend(response.message);
							   $(form).closest('.modal').modal("hide");
							}

							if(form.attr('data-target-content')){
							   $('#' + form.attr('data-target-content')).html(response.html);

							   bindControls();
                               
							   if(!form.attr('data-form-no-reset'))
                                  $(form).trigger("reset");

							}
							else{
							   $(form).prepend(response.message);
							   $('.modal-body').scrollTop(0);
							}
					});
		});

		$(document).delegate('*[data-modal]', 'click', function(e) {
           e.preventDefault();
		   
           var href = $(e.target).attr('href');
  		   if(!href) href = $(e.target).parent().attr('href');
		   if(!href) return;

           /* Copy link over to a data attr and set it back 1.5 seconds later
		      Used to prevent double clicking on links */
		   $(e.target).removeAttr('href');
		   $(e.target).data('oldlink', href);
		   setTimeout(function() {
                    $(e.target).attr('href', href);
                }, 1500);
				
           // If we are using Oracle flexible parameter passing then add timestamp to avoid caching
		   if(href.indexOf("?")>0)
			   href += "&_ts=" + new Date().getTime();
		   else 
			   href += "?_ts=" + new Date().getTime();
              
           var modalclass = $(e.target).data('modal-class');
           if(!modalclass)
              modalclass = $(e.target).parent().data('modal-class');
              
           if (href.indexOf('#') == 0) 
           {
              $(href).modal();
           } 
           else 
           {
              $.get(href, function(data) {
                 var popup = '<div class="modal ' + modalclass + '"> <div class="modal-body"><a class="close" data-dismiss="modal">&times;</a>' + data + '</div></div>';
                 var processed = $(popup).modal({backdrop: 'static', keyboard: false}).on("hide", function(e){
               if (e.target == this) {
                $('.modal').remove();
                     $('.modal-backdrop').remove();
               }
                 });
                 $('body').append(processed);

                 bindControls();
              });
           }
        });		
		
		$('.va-ajax-refresh').each(function(index, form) {$(form).vaAjaxRefresh();});	

		/* Calendar Handling */
		$('.ajaxCalendar').each(
			function(index, item) {
				$(item).fullCalendar({header: {left: 'prev,next today', center: 'title', right: 'listMonth,month'}, events: $(item).data("event-feed"), eventRender: function(event, element) {element.attr('title', event.title);}}); 
			});

		$("a[href='#top']").click(function() {
		  $("html, body").animate({ scrollTop: 0 }, "slow");
		  return false;
		});		

        /* Code Editor Handling */
        $('.codeeditor').each(
           function() {
			   var codeMirror = CodeMirror.fromTextArea($(this)[0], 
				{lineNumbers: true, 
				 fullscreen: true,
				 readonly: false,
				 extraKeys: {
					"F11": function(cm) {
					  cm.setOption("fullScreen", !cm.getOption("fullScreen"));
					},
					"Esc": function(cm) {
					  if (cm.getOption("fullScreen")) cm.setOption("fullScreen", false);
				    }	
				 }
			});	
		});	
		
    });    

   
    /*******************************************************************/
    /*********** DASHBOARD CUSTOMISATION LOGIC *************************/
    /*******************************************************************/    
	$(document).ready( function () {

	  var dashboardPrefix = "va-dashboard-";

	  $('.btn-dashboard-customise').on('click', function(e) {
		 e.preventDefault();

		 /* Switch Click */
		 if($(this).data("IsSave")==true) {
			$(this).data('IsSave', false);
			$(this).html('Customise');
			
			/* Save changes */
			localStorage = window.localStorage;
			$(".dashboard-widget").each(function(index) 
			{
			   widgetData = {'visible': $(this).data('visible'), 'position': $(this).index()};
			   localStorage.removeItem(dashboardPrefix + $(this).attr('id'));
			   localStorage.setItem(dashboardPrefix + $(this).attr('id'), JSON.stringify(widgetData));
			}); 

			/* Refresh screen */
			location.reload();
		  }
		 /* Customise clicked, show controls */
		 else {
			$(this).data('IsSave', true);
			$(this).html('Save Changes');

			$(".dashboard-widget").show();
			$(".dashboard-controls").show();

			$(".dashboard-container").each(function(index) {
			  $(this).addClass("sortable ui-sortable");
			});

			$('.dashboard-container').sortable();
		 }


		 // loop through dashboard widgets and add class "display-controls"
		 //$('.dashboard-controls')
	  });

	  $(document).on('click', '.btn-dashboard-show', function(e) {
		  var ButtonMode = $(this).data("button-mode");
		  if(ButtonMode=='hide')
		  {
			 $(this).parent().parent().data('visible', false)
			 $(this).parent().parent().addClass("hidden-widget");
			 $(this).html('Show');
			 $(this).data('button-mode', 'show')
		  }
		  else
		  {
			 $(this).parent().parent().data('visible', true)
			 $(this).parent().parent().removeClass("hidden-widget");
			 $(this).html('Hide');
			 $(this).data('button-mode', 'hide')
		  }

		  

	  //    widget_id:true/false:<position>
	  //    $(this).data('widget-id');

	  });

	  function RenderDashboard()
	  {
         var counter = 0;
		 if($(".dashboard-container").length==0)
			return;

		 localStorage = window.localStorage;

		 /* Add dashboard controls here */
		  $(".dashboard-widget").each(function( index ) {

			$(this).prepend('<div class="dashboard-controls">\
				   <a href="#" class="btn btn-mini btn-dashboard-show" data-widget-id="' + $(this).attr('id') +'">Show</a>\
				   <a href="#" class="btn btn-mini"> <i class="icon-move icon-large btn-dashboard-move"> </i></a>\
				</div>');
			});

		 /* Check if widgets are visible or not */
		 $(".dashboard-widget").each(function(index) {
			 counter++;
			 widgetData = localStorage.getItem(dashboardPrefix + $(this).attr('id'));
			 widgetDataJson = {"visible": true, "position": counter};
			 if(widgetData)
				widgetDataJson = jQuery.parseJSON(widgetData);

			 dashboardShowButton = $(this).find(".btn-dashboard-show");

			 $(this).data('position', widgetDataJson.position);

			 if(widgetDataJson.visible==true)
			 {
				$(dashboardShowButton).html('Hide');
				$(dashboardShowButton).data('button-mode', 'hide');
				$(this).data('visible', true);
			 }
			 else
			 {
				$(dashboardShowButton).html('Show');
				$(dashboardShowButton).data('button-mode', 'show');
				$(this).addClass("hidden-widget");
				$(this).hide();
				$(this).data('visible', false);
			 }


		 });

		 $(".dashboard-controls").hide();
		 $(".dashboard-container").each(function(index) {
			$(this).sortDivs();
		 });
		 $(".dashboard-container").show();
 		 
		 $('.ajaxCalendar').each(
			function(index, item) {
				$(item).fullCalendar("render"); 
			});

		 }
		 
	     RenderDashboard();

	});

	/* IE11 Fixes */
  if (!String.prototype.includes) {
    String.prototype.includes = function(search, start) {
      if (typeof start !== 'number') {
        start = 0;
      }

      if (start + search.length > this.length) {
        return false;
      } else {
        return this.indexOf(search, start) !== -1;
      }
    };
  }	
